import React, { useEffect } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import navbarimage from "assets/img/layout/Navbar.png";
import { BsArrowBarUp } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import avatar from "assets/img/avatars/avatar4.png";
import { useAuth0 } from "@auth0/auth0-react";

let returnTo;
const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;

  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains("dark")
  );
  useEffect(() => {
    document.body.classList.add("dark");
    setDarkmode(true);
  }, []);
  const { logout } = useAuth0();
  const {user} = useAuth0();
  if (window.location.hostname === "localhost") {
    returnTo = "http://localhost:3000/";
  } else {
    // Replace with your production URL
    returnTo = "https://app.rely.business/empresa/nueva";
  }
  return (
    
    <nav
      w={{
        base: "calc(100vw - 6%)",
        md: "calc(100vw - 8%)",
        lg: "calc(100vw - 6%)",
        xl: "calc(100vw - 350px)",
        "2xl": "calc(100vw - 365px)",
      }}
      className="fixed top-3 right-3 flex w-[calc(100vw_-_6%)] flex-row flex-wrap items-center justify-between rounded-xl bg-white/30  p-2 backdrop-blur-xl dark:bg-[#0b232323] md:top-4 md:right-[30px] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:top-[20px] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]"
    >
      <div className="ml-[6px]">
     
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[185px] xl:gap-2">
        {/* <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div> */}
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden "
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        {/* start Notification */}

        {/* <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
            
            
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
          <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
        </div> */}
        {/* <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdNotificationsOutline className="h-4 w-4 text-gray-600 dark:text-white" />
            </p>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-navy-700 dark:text-white">
                  Notification
                </p>
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  Mark all read
                </p>
              </div>

              <button className="flex w-full items-center">
                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                  <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                    No new notifications
                  </p>
              
                </div>
              </button>
            </div>
          }
          classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
        /> */}
        {/* Profile & Dropdown */}
        <Dropdown
          //Si el usuario no esta autentificado mostrar el avatar por defecto
          //Si el usuario no esta autentificado mostrar el avatar por defecto//Si el usuario esta autentificado mostrar primer letra del nombre
          button= {
            !user ?
            <img
              src={avatar}
              alt="avatar"
              className="h-10 w-10 rounded-full cursor-pointer"
            />
            :
            //Circulo con la primera letra del nombre
            <div className="h-10 w-10 rounded-full bg-green-700 text-white flex items-center justify-center cursor-pointer">
              {user.name.charAt(0).toUpperCase()}
            </div>

          }
          
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          
          

          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                              {/* traer el mail del usuario de auth0 */}          
                <p className="text-base font-bold text-navy-700 dark:text-white">
                  {user && user.email}
                </p>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="mt-3 ml-4  flex-col">
                {/*boton rojo y centrado que ocupe todo el anchode la caja */}
              <button className="bg-red-500 text-white rounded-xl px-12 py-2 font-medium" onClick={() => logout({ logoutParams: { returnTo: returnTo } })}>
                Cerrar Sesion
              </button>
                {/* <a 
                  href=" "
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Newsletter Settings
                </a> */}
                {/* <a
                  href="/"
                  onClick={() => logout({ returnTo: window.location.origin })}
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Log Out
                </a> */}
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;
