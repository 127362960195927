import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import profileAvatar from 'assets/img/avatars/avatar4.png';
import Card from 'components/card';
import InputField from 'components/fields/InputField'; 

const Profile = () => {
  const { user, isLoading } = useAuth0();
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (user) {
      setEditData({ ...user });
    }
  }, [user]);

  const excludedFields = ['sub', 'updated_at', 'picture'];

  const generateFields = (userData) => {
    if (!userData) {
      return null;
    }
    return Object.keys(userData).filter(key => !excludedFields.includes(key)).map((key) => {
      if (typeof userData[key] === 'string') {
        return (
          <InputField
            key={key}
            label={key.charAt(0).toUpperCase() + key.slice(1)}
            placeholder={`Enter ${key}`}
            value={editData[key] || ''}
            onChange={() => {}} // Desactivar la edición
            readOnly={true} // Hacer el campo de solo lectura
          />
        );
      }
      return null;
    }).filter(Boolean);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Card extra={'w-full py-[18px] px-[18px] h-auto mt-[55px]'}>
      <div className="flex gap-3 items-center">
        {/* <div className="h-[90px] w-[90px] flex items-center justify-center rounded-full">
          <img
            className="h-full w-full rounded-full"
            src={profileAvatar}
            alt="profile"
          />
        </div> */}
        <div className="flex-1">
          {generateFields(user)}
        </div>
      </div>
    </Card>
  );
};

export default Profile;
