import React, { useEffect, useState } from 'react';
import { Card, Button } from 'reactstrap';

function SuccessPage() {
  const [loading, setLoading] = useState(true);

  const handleButtonClick = () => {
    const baseUrl = process.env.NODE_ENV === 'production' ? 'https://app.rely.business/' : 'http://localhost:3000';
    window.location.href = baseUrl;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setTimeout(() => {
        window.location.href = '/empresa/nueva'; // Usa window.location.href para redirigir
      }, 3000); // Redirige después de 2 segundos
    }, 1000); // Cambia a false después de 1 segundo

    return () => clearTimeout(timer); // Limpia el temporizador si el componente se desmonta
  }, []);

  return (
    <div className="flex items-center justify-center rounded-[20px] h-[300px] bg-green-700">
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="bouncing-ballY"></div>
        </div>
      ) : (
        <Card className="text-center">
          <h1 className="s font-bold mb-4 text-white sm:text-lg md:text-[20px] xl:!text-[30px] 2xl:pt-3 float-effect">
            ¡Recibimos tu pago con exito!
          </h1>
          <h3 className="s font-bold text-white sm:text-lg md:text-[10px] xl:!text-[20px] 2xl:pt-3 float-effect">
            Ahora completa los datos de tu empresa para continuar con el registro.
          </h3>
        </Card>
      )}
    </div>
  );
}

export default SuccessPage;
