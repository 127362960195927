import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { tableColumnsReceipt } from "views/admin/main/ecommerce/orderDetails/variables/tableColumnsReceipt";
import ReceiptTable from "./ReceiptTable";

const Content = ({ companyId }) => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const id = companyId.companyId;
      if (typeof id === 'string') {
        setIsLoading(true); // Inicia la carga
        try {
          const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.rely.business' : 'http://127.0.0.1:5000';
          const result = await axios.get(`${baseUrl}/api/documents?status=read&company_id=${id}`);
          if (Array.isArray(result.data.result)) {
            setTableData(result.data.result);
          }else {
            console.error('API did not return an array');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false); // Finaliza la carga
        }
      }
    };
  
    fetchData();
  }, [companyId])

  return (
    <div className="w-full bg-white pb-[57px] dark:!bg-navy-800">
      {isLoading ? (
        <div className="flex justify-center items-center mt-[60px]">
          <div className="bouncing-ball"></div>
        </div>
      ) : (
        <div>
          <ReceiptTable
            tableData={tableData}
            columnsData={tableColumnsReceipt}
          />
        </div>
      )}
      {/* Note */}
      {/* Rest of your component */}
    </div>
  );}
  
  
export default Content;