import React, { useMemo, useState } from "react";
import { useTable, useGlobalFilter } from "react-table";
import { MdSearch } from "react-icons/md"; // Importar el ícono de búsqueda

const ReceiptTable = ({ columnsData, tableData }) => {
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter);

  const [filterInput, setFilterInput] = useState("");

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setFilterInput(value);
  };

  return (
    <div className="h-full w-full overflow-x-auto py-[25px] dark:bg-navy-800 lg:overflow-x-hidden ">
      <div className="flex justify-center w-full pb-5 px-5 md:w-1/2 lg:w-1/3">
        <div className="flex items-center w-full space-x-2 p-1 border border-gray-300 rounded-md shadow-sm bg-white/0">
          <MdSearch className="text-gray-500" size={24} />
          <input
            type="text"
            value={filterInput}
            onChange={handleFilterChange}
            placeholder="Buscar..."
            className="flex-grow p-1 focus:outline-none bg-white/0"
          />
        </div>
      </div>
      {rows.length === 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <p>Aun no hay documentos para esta empresa</p>
        </div>
      ) : (
        <table {...getTableProps()} className="w-full text-gray-600">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps()}
                    className="border-b border-gray-200 px-4 text-start dark:border-white/10"
                    key={index}
                  >
                    <div className="pb-[11px] text-xs font-bold uppercase text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => (
                    <td
                      {...cell.getCellProps()}
                      className="border-b border-gray-200 px-[23px] py-[25px] dark:border-white/10"
                      key={index}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ReceiptTable;
