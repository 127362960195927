import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import Card from 'components/card';
import Checkbox from 'components/checkbox';
import SuccessPage from 'views/admin/main/success';
import { useAuth0 } from '@auth0/auth0-react'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStripe, faCcVisa, faCcMastercard } from '@fortawesome/free-brands-svg-icons';

// Replace 'your_stripe_public_key' with your actual Stripe public key
const stripePromise = loadStripe('pk_live_51O4qCOJjJUUpzPD47UYgsSBsyRb5qQuS8fGMNpAM6IpVtwPxcxMtam2ISaHC6rZ7FJiSNP8jfqSNcxmNEE0kPVsA00y0irDp2I');

const Profile = () => {
  const [isPaymentVisible, setIsPaymentVisible] = useState(false);

  const { user } = useAuth0(); // Extrae el usuario de Auth0
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const handlePayment = async () => {
    if (!stripe || !elements) {
      console.log("Stripe.js has not loaded yet.");
      return;
    }
  
    setLoading(true);
    setError(null);
  
    // Obtener cada uno de los elementos individuales
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    // Verificar que todos los elementos están presentes
    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      console.error("Stripe card elements not found");
      setError("Error en el procesamiento de la tarjeta.");
      setLoading(false);
      return;
    }
  
    // Crear el método de pago utilizando el elemento del número de tarjeta
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    });
  
    if (error) {
      console.error("Payment error:", error);
      setError(error.message);
      setLoading(false);
    } else {
      console.log("PaymentMethod", paymentMethod);
      setLoading(false);
      submitPayment(paymentMethod.id, companyName, user.email, cost);
    }
  };
  

  const submitPayment = async (paymentMethodId, companyName, userEmail, precioTotal) => {
    try {
      const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.rely.business' : 'http://127.0.0.1:5000';
      const response = await fetch(`${baseUrl}/api/create-payment-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ paymentMethodId, companyName, userEmail, precioTotal }),
      });

      const data = await response.json();
      if (data) {
        setPaymentSuccess(true);
        localStorage.setItem('currentStep', 2);
        localStorage.setItem('CompanyName', companyName);
      } else {
        setError(data.message);
      }
      setLoading(false);
    } catch (err) {
      console.error('Error submitting payment:', err);
      setError('Failed to submit payment.');
      setLoading(false);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#fff", // Text color set to white
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        '::placeholder': {
          color: '#aab7c4' // Placeholder text color
        }
      },
      invalid: {
        color: "#fa755a", // Color when the user inputs invalid data
        iconColor: "#fa755a"
      }
    }
  };
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const [showPaymentInputs, setShowPaymentInputs] = useState(false);
  const [activePlan, setActivePlan] = useState("Starter");
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [cost, setCost] = useState(390);


  const handleTermsAndConditions = (e) => {
    setTermsAndConditions(e.target.checked);
    
  };
  const handleActivePlan = (plan) => {
    setActivePlan(plan);
    if (plan === "Starter") {
      setCost(399);
    } else {
      setCost(599);
    }
  };

  useEffect(() => {
    const isComplete = companyName !== '' && termsAndConditions;
    setShowPaymentInputs(isComplete);
    if (showPaymentInputs) {
      // Aquí utilizamos un tiempo de espera para cambiar el estado y que se muestre con la animación
      const timer = setTimeout(() => setIsPaymentVisible(true), 100); // 100 milisegundos o el tiempo que desees
      return () => clearTimeout(timer); // Limpiamos el timer cuando el componente se desmonte
    } else {
      setIsPaymentVisible(false);
    }
  }, [companyName, termsAndConditions, paymentSuccess,showPaymentInputs]);

  if(!paymentSuccess){
    return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
 

      <h4 className="pt-[10px] py-[10px] text-l font-bold text-navy-700 dark:text-white">
        Elegi el plan que mas te convenga
      </h4>

      <Card className="border-2 border-green-500 rounded-lg overflow-hidden maxw-[400px]">
        <div className="flex justify-center items-center mt-4">
          <button
            className={`mx-2 py-2 px-4 rounded-full text-xs font-bold uppercase transition duration-200 ${
              activePlan === "Starter"
                ? "bg-green-700 text-white"
                : "bg-transparent text-green-500 border border-green-500"
            }`}
            onClick={() => handleActivePlan("Starter")}
          >
            Starter
          </button>
          <button
            className={`mx-2 py-2 px-4 rounded-full text-xs font-bold uppercase transition duration-200 ${
              activePlan === "Professional"
                ? "bg-green-700 text-white"
                : "bg-transparent text-green-500 border border-green-500"
            }`}
            onClick={() => handleActivePlan("Professional")}
          >
            Professional
          </button>
        </div>
        <div className="flex justify-center items-center mx-2 py-2">
        {activePlan === "Starter" && (
          <ul className="mt-4 text-base font-small text-white-700 px-4">
            <li>- 1 año de Agente de Registro ✅</li>
            <li>- Artículos de Organización ✅</li>
            <li>- EIN ✅</li>
          </ul>
        )}
        {activePlan === "Professional" && (
          <ul className="mt-4 text-base font-small text-white-700 px-4">
           
            <li>- 1 año de Agente de Registro ✅</li>
            <li>- Artículos de Organización ✅</li>
            <li>- EIN ✅</li>
            <li>- Dominio y Web Simple ✅</li>
            <li>- Mailbox ✅</li>
            <li>- Consulta Bancaria ✅</li>
          </ul>
        )}
        </div>
      </Card>

      <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-3">
      {/* Input for company name */}
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nombre de tu empresa</label>
        <input
          type="text"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="Ingresa el nombre de tu empresa"
          // Aquí puedes agregar el manejo del estado, por ejemplo:
          // value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
      </div>

      {/* Dropdown for state selection */}
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Selecciona tu estado</label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          disabled
        >
          <option value="Wyoming">Wyoming</option>
        </select>
      </div>

      {/* Dropdown for company type */}
      <div>
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tipo de empresa</label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          disabled
        >
          <option value="LLC">LLC</option>
        </select>
      </div>
      </div>
      <p className="mt-[25px] text-base font-small text-green-700">
        Tendras tu empresa registrada y con EIN en 96 horas.
      </p>
      <div className="mt-5">
        <h5 className="font-dm text-4xl font-bold text-navy-700 dark:text-white">
          <span>Total= </span>${cost}<span className="text-gray-600">/ única vez</span>
        </h5>
      </div>

      <div className="flex items-center mt-6 mb-6">
        <Checkbox
          checked={termsAndConditions}
          onChange={(e) => handleTermsAndConditions(e)}
          color="green"
        />
        <label className="ml-2 block text-sm font-medium text-white">
          Acepto términos y condiciones.
        </label>
      </div>
      {isPaymentVisible && (
  <div className={`my-4 p-4 shadow-lg rounded-lg my-card-container fade-in`}>
    <h4 className="text-lg font-medium text-white text-center ">Ingresa tu tarjeta</h4>
      <Card>
        <div className="p-4 flex flex-col items-center justify-center">
          <div className="my-4 w-full max-w-md">
            <div className="flex flex-col md:flex-row md:space-x-4 justify-center items-center">
              <CardNumberElement options={CARD_ELEMENT_OPTIONS} className="mb-4 md:mb-0 w-full" />
              <CardExpiryElement options={CARD_ELEMENT_OPTIONS} className="mb-4 md:mb-0 w-full" />
              <CardCvcElement options={CARD_ELEMENT_OPTIONS} className="w-full" />
            </div>

            <div className="mt-4 flex justify-center items-center">
              <FontAwesomeIcon icon={faStripe} size="2x" style={{ color: '#ffffff', marginRight: '10px' }} />
              <FontAwesomeIcon icon={faCcVisa} size="2x" style={{ color: '#ffffff', marginRight: '10px' }} />
              <FontAwesomeIcon icon={faCcMastercard} size="2x" style={{ color: '#ffffff' }} />
            </div>
            <button
              className="mt-4 bg-green-700 hover:bg-green-800 text-white font-bold py-2 px-4 rounded w-full"
              onClick={handlePayment}
              disabled={!stripe || loading}
            >
              {loading ? "Procesando..." : `Pagar U$ ${cost}`}
            </button>
            {error && <div className="text-red-500 mt-2">{error}</div>}
          </div>
        </div>
      </Card>
        </div>
      )}
     
    </div>

  );
  }
  if(paymentSuccess){
    return (
      <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
        <SuccessPage />
      </div>
    )
  }
};


export default Profile;
