import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const Details = ({ setCompanyId }) => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth0();
  const userMail = user?.email; // Optional chaining for safety

  useEffect(() => {
    const fetchCompanies = async () => {
      if (!userMail) {
        return;
      }

      setIsLoading(true);
      try {
        const baseUrl = process.env.NODE_ENV === 'production' 
          ? 'https://api.rely.business' 
          : 'https://api.rely.business';
        const response = await axios.get(`${baseUrl}/api/companies?userMail=${encodeURIComponent(userMail)}`);
        const results = response.data.map(item => item.result); // Map each item to its result
        if (results.length) {
          setCompanies(results);
          setSelectedCompany(results[0]);
          setCompanyId(results[0].id);
        } else {
          setCompanies([]);
          setSelectedCompany(null);
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanies();
  }, [userMail]);

  const handleCompanyChange = (e) => {
    const company = companies.find(c => c.id === e.target.value);
    setSelectedCompany(company);
    setCompanyId(company.id);
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-center rounded-[20px] bg-white bg-clip-border px-[20px] pt-5 pb-5 shadow-3xl shadow-shadow-500 dark:bg-navy-800 dark:shadow-none md:flex-row md:items-stretch">
      {isLoading ? (
        <div className="flex justify-center items-center">
          <div className="bouncing-ballX"></div>
        </div>
      ) : (
        <div className="flex flex-col w-full gap-5 items-center justify-center md:flex-row">
          {companies.length === 0 ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: 'white' }}>
              <p>Crea tu empresa</p>
            </div>
          ) : (
            <>
              <div className="flex flex-col justify-center items-center lg:w-1/3 md:w-1/3 md:px-1">
                <select onChange={handleCompanyChange} className="form-select w-full text-lg p-2 text-white text-bold bg-green-700 border border-green-700 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500">
                  {companies.map(company => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col justify-center items-center md:w-1/3 md:px-5">
                <p className="text-base font-medium leading-6 text-gray-600">
                  Address: 30 North Gould Street, 82801
                </p>
              </div>
              <div className="flex flex-col justify-center items-center md:w-1/3 md:px-5">
                <p className="text-base font-medium leading-6 text-gray-600">
                  Home State: {selectedCompany?.home_state}
                </p>
                <p className="text-base font-medium leading-6 text-gray-600">
                  Created At: {selectedCompany && new Date(selectedCompany.created_at).toLocaleDateString()}
                </p>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Details;
