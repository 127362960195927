import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';
import "./index.css";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
let redirectUri;
if (window.location.hostname === "localhost") {
  redirectUri = "http://localhost:3000/empresa/nueva";
} else {
  // Replace with your production URL
  redirectUri = "https://app.rely.business/empresa/nueva";
}

root.render(
  <Auth0Provider
  domain="dev-41h-z2e6.us.auth0.com"
  clientId="QlCzo0wIsaVIXJbfl0Hdbr3aRlWp8WN8"
  redirectUri= {redirectUri}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Auth0Provider>
);

