import { useState } from "react";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import UserInfo from "./components/steps/UserInfo";
import Address from "./components/steps/Address";
import Payment from "./components/steps/Payment";
import Profile from "./components/steps/Profile";
import Card from "components/card";
import Navbar from "components/navbar";
import { useAuth0 } from "@auth0/auth0-react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51O4qCOJjJUUpzPD4bbk3mOEBdQJEoIgNMq1C638Oh58qH1C4LfQQJtGdQD71k34jG76nNaXs0gucPJB9KRg3MwSb005rZOyBtL');

const NewUser = () => {
  const initialStep = localStorage.getItem('currentStep') ? Number(localStorage.getItem('currentStep')) : 1;
  const [currentStep, setCurrentStep] = useState(initialStep);
  const { isAuthenticated } = useAuth0();
  const { loginWithRedirect } = useAuth0();
  const [isProfileComplete, setIsProfileComplete] = useState(false);

  const steps = [
    { stepNo: 1, name: "Elige tu plan" },
    { stepNo: 2, name: "Info. de tu empresa" },

  ];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <Elements stripe={stripePromise}>
                  <Profile/>
                </Elements>
      case 2:
        return <UserInfo />;

    }
  };
  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };
  

  if (!isAuthenticated) {
    return (
      //boton en el centro vertical y horizontal
      <div className="mt-3 h-full w-full flex flex-col justify-center items-center">
        <h4 className="pt-[140px] text-xl font-bold text-navy-700 dark:text-white float-effect">
          Inicia sesión para continuar
        </h4>
        <button className="mt-10 cursor-pointer rounded-xl bg-green-700 px-16 py-2 font-medium text-white transition duration-200 hover:bg-green-800 active:bg-green-700 opacity-90 md:ml-2" onClick={loginWithRedirect}>Iniciar</button>      </div>
    );
  }

  return isAuthenticated && (
    <div className="mt-3 h-full w-full">
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-green-500 to-blue-500 md:h-[250px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper 
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>

        <Card extra={"h-full mx-auto pb-3 "}>
          <div className="rounded-[20px] ">
            <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
          </div>
          {/* navigation button */}
          {isProfileComplete && (
          <StepperControl
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
          />
        )}
        </Card>
      </div>
    </div>
  );
};

export default NewUser;
