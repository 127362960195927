import { MdAccountCircle, MdAdminPanelSettings, MdComputer, MdDomain, MdHelp, MdHelpCenter, MdHome, MdPhone } from 'react-icons/md';
import AccountSettings from 'views/admin/main/account/settings';
import UserNew from 'views/admin/main/users/newUser';
import ApplicationsDataTables from 'views/admin/main/applications/dataTables';
import EcommerceOrderDetails from 'views/admin/main/ecommerce/orderDetails';
import ServiceOrderDetails from 'views/admin/main/servicios/orderDetails';
import OrderDetails from 'views/admin/main/order/orderDetails';
import SupportSettings from 'views/admin/main/support/settings';
import EcommerceReferrals from 'views/admin/main/ecommerce/referrals';
import { useAuth0 } from '@auth0/auth0-react';

const routes = [
  // --- Redirect ---

  // isAuth === false && {
  //     name: 'Adminstracion',
  //     path: '/admin',
  //     icon: <MdAdminPanelSettings className="text-inherit h-5 w-5" />,
  //     collapse: true,
  //     items: [
  //       {
  //         name: 'Panel de Admin',
  //         layout: '/admin',
  //         path: '/panel',
  //         exact: false,
  //         component: <ApplicationsDataTables />,
  //       },
  //     ],
  //   },
  // --- Main pages ---
{
    name: 'Empresas',
    path: '/dashboard',
    icon: <MdDomain className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: 'Crear Empresa',
        path: '/nueva',
        exact: false,
        layout: '/empresa',
        component: <UserNew />,
      },
      {
        name : 'Documentos',
        path: '/documentos',
        layout: '/empresa',
        component: <EcommerceOrderDetails />,
      },
      // {
      //   name: 'Servicios',
      //   path: '/servicios',
      //   layout: '/empresa',
      //   exact: false,
      //   component: <ServiceOrderDetails />,
       
      // },
      // {
      //   name: 'Ordenes',
      //   path: '/ordenes',
      //   layout: '/empresa',
      //   exact: false,
      //   component: <OrderDetails />,
       
      // },
    ],
  },
    // --- Authentication ---
    {
      name: 'Cuenta',
      path: '/auth',
      icon: <MdAccountCircle className="text-inherit h-5 w-5" />,
      collapse: true,
      items: [
        // --- Lock ---
        {
          name: 'Configuracion',
          layout: '/empresa',
          path: '/cuenta',
          exact: false,
          component: <AccountSettings />,
        },
        // {
        //   name: 'Soporte',
        //   layout: '/empresa',
        //   path: '/soporte',
        //   exact: false,
        //   component: <AccountSettings />,
        // },
        // {
        //   name: 'Referidos',
        //   layout: '/empresa',
        //   path: '/referidos',
        //   exact: false,
        //   component: <EcommerceReferrals />,
        // },
      ],
    },
    // {
    //   name: 'Soporte',
    //   path: '/auth',
    //   icon: <MdHelp className="text-inherit h-5 w-5" />,
    //   collapse: true,
    //   items: [
    //     // --- Lock ---
    //     {
    //       name: 'Contactanos',
    //       layout: '/empresa',
    //       path: '/contactanos',
    //       exact: false,
    //       component: <SupportSettings />,
    //     },
    //   ],
    // },
    
    //Documentos

];
export default routes;
