import axios from 'axios';
import { MdDownload } from 'react-icons/md';
import { useState } from 'react';
// ...

const downloadFile = async (id, setLoading) => {
  setLoading(true);
  const baseUrl = process.env.NODE_ENV === 'development'
    ? "http://127.0.0.1:5000"
    : "https://api.rely.business"

  try {
    const response = await axios.get(`${baseUrl}/api/documents/bulk-download`, {
      params: {
        document_id: id,
      },
    });
    const url = response.data.result[0].url;
    window.open(url, '_blank');
  } catch (error) {
    console.error('Error downloading the file:', error);
  } finally {
    setLoading(false);
  }
};
export const tableColumnsReceipt = [

  // ...

  // {
  //   Header: 'Company Name',
  //   accessor: 'company_name',
  // },
  // {
  //   Header: 'Jurisdiction',
  //   accessor: 'jurisdiction',
  // },
  // {
  //   Header: 'Title',
  //   accessor: 'title',
  // },
  {
    Header: 'Descargar',
    accessor: 'id', // Usamos el 'id' para generar el enlace de descarga
    Cell: ({ value }) => {
      const [loading, setLoading] = useState(false);
      return (
        <button
          onClick={() => downloadFile(value, setLoading)}
          className="text-blue-500 hover:text-green-700"
        >
          {loading ? (
            <div className="flex justify-center items-center">
              <div className="bouncing-ballX"></div>
            </div>
          ) : (
            <MdDownload className="text-inherit h-5 w-5" />
          )}
        </button>
      );
    },
  },
  {
    Header: 'Recibido',
    accessor: 'created_at',
    Cell: ({ value }) => new Date(value).toLocaleDateString(), // Formatea la fecha
  },
  {
    Header: 'Titulo',
    accessor: 'type',
  },
  {
    Header: 'Estado',
    accessor: 'status',
  },

  // {
  //   Header: 'Updated At',
  //   accessor: 'updated_at',
  //   Cell: ({ value }) => new Date(value).toLocaleDateString(), // Formatea la fecha
  // },
  // {
  //   Header: 'Pages',
  //   accessor: 'pages',
  // },
    // {
    //   Header: 'Payment Locked',
    //   accessor: 'payment_locked',
    //   Cell: ({ value }) => (value ? 'Yes' : 'No'), // Convierte el booleano en texto
    // },
    // {
    //   Header: 'Source',
    //   accessor: 'source',
    // },
  
];
