import { useAuth0 } from "@auth0/auth0-react";
import Details from "./components/Details";
import OrderStatus from "./components/OrderStatus";
import Receipt from "./components/Receipt";
import { redirect, useNavigate } from "react-router-dom";
import { useState } from "react";
const OrderDetails = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState(null); // Add this line
  if(isAuthenticated){
    return (
      <div className="mt-3 grid h-full w-full grid-cols-7 mt-[50px] gap-[20px] rounded-[20px]">
        <div className="z-0 col-span-7 lg:col-span-7">
          <Details setCompanyId={setCompanyId} /> {/* Pass setCompanyId to Details */}
        </div>
        {/* <div className="z-0 col-span-7 lg:col-span-2">
          <OrderStatus />
        </div> */}
        <div className="z-0 col-span-7 lg:col-span-7">
          <Receipt companyId={companyId} /> {/* Pass companyId to Receipt */}
        </div>
      </div>
    );
  }
  if (!isAuthenticated) return (
    <div className="mt-3 h-full w-full flex flex-col justify-center items-center">
    <h4 className="pt-[140px] text-xl font-bold text-navy-700 dark:text-white float-effect">
      Aun no creaste ninguna empresa...
    </h4>
    <button className="mt-10 cursor-pointer rounded-xl bg-green-700 px-16 py-2 font-medium text-white transition duration-200 hover:bg-green-800 active:bg-green-700 opacity-90 md:ml-2" onClick={() => navigate("/empresa/nueva")}>
      Crear Empresa
    </button>

  </div>
  )
};

export default OrderDetails;
