import { MdShare, MdEdit, MdDownload } from "react-icons/md";
import Details from "assets/img/ecommerce/Details.png";
import Content from "./Content";
import Card from "components/card";
const Receipt = (companyId) => {
  return (
    
    <Card extra={"w-full h-full px-4 py-4"}>
      {/* Banner */}
      <div
          className="flex w-full items-center justify-between rounded-2xl bg-cover bg-no-repeat px-2 pb-3 pt-4 md:!h-[60px] md:items-start md:pl-[20px] md:pr-[40px] 2xl:!h-[60px] bg-green-700"
        >
          <div className="flex items-center">
            <h2 className="s font-bold text-white sm:text-lg md:text-[20px] xl:!text-[20px]">
              Documentos Recibidos
            </h2>
          </div>
          </div>
        {/* <div className="mt-[13px] flex gap-[12px] md:mt-0">
          <button className="flex items-center justify-center rounded-lg bg-brandLinear p-2 text-xl text-white transition-opacity hover:cursor-pointer hover:opacity-80">
            <MdEdit />
          </button>
          <a href="https://docdro.id/jNrQiMH" target="blank">
            <button className="flex items-center justify-center rounded-lg bg-brandLinear p-2 text-xl text-white transition-opacity hover:cursor-pointer hover:opacity-80">
              <MdDownload />
            </button>
          </a>
          <button className="flex items-center justify-center rounded-lg bg-gradient-to-br from-[#FA709A] to-[#FEE140] p-2 text-xl text-white hover:cursor-pointer">
            <MdShare />
          </button>
        </div> */}
     
      {/* content */}
      <div className="h-full w-full">
        <Content companyId={companyId}/>
      </div>
    </Card>
  );
};

export default Receipt;
