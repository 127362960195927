import Information from "./components/Information";
import Connect from "./components/Connect";
import Delete from "./components/Delete";
import NewsLetter from "./components/NewsLetter";
import Password from "./components/Password";
import Profile from "./components/Profile";
import Session from "./components/Session";
import Social from "./components/Social";
import TwoFactor from "./components/TwoFactor";
import { useAuth0 } from "@auth0/auth0-react";
const Setting = () => {
  const { isAuthenticated } = useAuth0();
  const { loginWithRedirect } = useAuth0();
  console.log(isAuthenticated)
  if(isAuthenticated){
  return (
    <div className="flex justify-center">
        <div className="mt-3 w-full max-w-4xl px-4 sm:px-6 lg:px-8 flex flex-col items-center">
            <div className="flex flex-col gap-5">
                <Profile />
                {/* <Information /> */}
                {/* <Social /> */}
                {/* <TwoFactor /> */}
                <Password />
            </div>
        </div>
    </div>
      );
    }
    if(!isAuthenticated){
      return(
        <div className="mt-3 h-full w-full flex flex-col justify-center items-center">
        <h4 className="pt-[140px] text-xl font-bold text-navy-700 dark:text-white float-effect">
          Inicia sesión para continuar :)
        </h4>
        <button className="mt-10 cursor-pointer rounded-xl bg-green-700 px-16 py-2 font-medium text-white transition duration-200 hover:bg-green-800 active:bg-green-700 opacity-90 md:ml-2" onClick={loginWithRedirect}>Iniciar</button>      </div>
)
    }
};

export default Setting;
