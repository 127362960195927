import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FaCcMastercard, FaCcStripe, FaCcVisa } from "react-icons/fa";
import YourCard from 'views/admin/dashboards/default/components/YourCard';
import PaymentCard from 'views/admin/main/users/newUser/components/steps/Payment';
import Checkbox from 'components/checkbox';
import Radio from 'components/radio';
import Card from 'components/card';

const stripePromise = loadStripe('pk_live_51O4qCOJjJUUpzPD47UYgsSBsyRb5qQuS8fGMNpAM6IpVtwPxcxMtam2ISaHC6rZ7FJiSNP8jfqSNcxmNEE0kPVsA00y0irDp2I');

const Payment = () => {
    const cost = localStorage.planCost;
    const stripe = useStripe();
    const elements = useElements();
  
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [activeCountry, setActiveCountry] = useState(null);
    const [paymentError, setPaymentError] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [processing, setProcessing] = useState(false);

    const handleTermsAndConditionsToggle = () => setTermsAndConditions(prev => !prev);
    const [paymentMethod, setPaymentMethod] = useState('stripe');
    const [stripeChecked, setStripeChecked] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
        });

        if (error) {
            setPaymentError(error.message);
            setPaymentSuccess(null);
        } else {
            setPaymentSuccess(paymentMethod.id);
            setPaymentError(null);
        }
    };

 useEffect(() => {
    const fetchClientSecret = async () => {
        const response = await fetch('https://api.rely.business/api/create-payment-intent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                amount: cost,
                currency: 'usd',
            }),
        });
        const data = await response.json();
        setClientSecret(data.client_secret);
    };
    fetchClientSecret();
}, []);

    const cardStyles = {
        base: {
            fontSize: "20px",
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    };

    return (
        <Card extra={`w-full h-full pb-6 pt-8 px-[20px] border-0 transition duration-200 ${selectedCard && activeCountry ? 'border-green-500' : ''}`}>
            <h4 className="text-l font-bold text-navy-700 dark:text-white flex flex-col">
                <div>Pais: {localStorage.selectedCountry}</div>
                <div>Plan: {localStorage.selectedPlan}</div>
                <div>Total: USD {cost}</div>
            </h4>

            {termsAndConditions && (
                <div className="mt-4 grid grid-cols-1 gap-3 md:grid-cols-1">
                    <div className="flex items-center">
                        <p style={{ marginRight: "10px" }}>Pagar con Tarjeta</p>
                    </div>
                    
                    <PaymentCard />
                </div>
            )}
            {cost > 0 && (
                <div className="flex items-center">
                    <Checkbox style={{ marginRight: "10px" }} color="green" onChange={handleTermsAndConditionsToggle} />
                    <p>
                        Acepto los 
                        <a className="text-green-500 underline" href='https://rely.business/terminos' target='_blank' rel="noopener noreferrer">
                            términos y condiciones
                        </a>
                    </p>
                </div>
            )}
        </Card>
    );
};

const PaymentWithStripe = () => (
    <Elements stripe={stripePromise}>
        <Payment />
    </Elements>
);

export default PaymentWithStripe;
