import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import RTLLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import CompanyLayout from "layouts/empresa";
import AuthLayout from "layouts/auth";
import "assets/css/Plugins.css";
import TermsAndConditions from "views/admin/main/terminos";
import PrivacyPolicy from "views/admin/main/privacidad";
const App = () => {
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="empresa/*" element={<CompanyLayout />} />
      <Route path="admin/panel"element={<AdminLayout />} />
      <Route path="terminos" element={<TermsAndConditions />} />
      <Route path="privacidad" element={<PrivacyPolicy />} />
      <Route path="/" element={<Navigate to="/empresa/nueva" replace />} />
    </Routes>
  );
};

export default App;
