import React, { useState } from 'react';
import SuccessCompanyInfo from 'views/admin/main/success/companyinfo';

function InputField({ label, id, type = "text", placeholder, value, onChange, error, className = "", max }) {
  return (
    <div className={`mb-3 ${className}`}>
      <label htmlFor={id} className="block text-sm font-medium text-white">
        {label}
      </label>
      <input
        type={type}
        id={id}
        name={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        max={max}
        className={`mt-1 block w-full rounded-md border p-3 text-sm outline-none bg-white/0 ${
          error ? 'border-red-500' : 'border-gray-300'
        }`}
      />
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
}

function SelectionField({ label, id, options, value, onChange, error }) {
  return (
    <div className="mb-3 w-full">
      <label htmlFor={id} className="block text-sm font-medium text-white">
        {label}
      </label>
      <select
        id={id}
        value={value}
        onChange={onChange}
        className={`mt-1 block w-full rounded-md border p-3 text-sm outline-none bg-transparent text-white ${
          error ? 'border-red-500' : 'border-gray-300'
        }`}
        style={{ backgroundColor: 'rgba(255, 255, 255, 0)', color: 'white' }}
      >
        <option value="" disabled hidden>Seleccione una opción</option>
        {options.map((option) => (
          <option key={option.value} value={option.value} style={{ backgroundColor: '#333', color: 'white' }}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
}

const countries = [
  { value: "AF", label: "Afghanistan" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AR", label: "Argentina" },
  // Agrega más países según sea necesario
];

const UserInfo = () => {
  const [saved, setSaved] = useState(false);
  const [formState, setFormState] = useState({
    members: [{ firstName: '', lastName: '', email: '', role: '', ownershipPercentage: '', birthdate: '' }],
    contactInfo: {
      name: '',
      lastName: '',
      email: '',
      direction: {
        country: '',
        state: '',
        city: '',
        address: '',
        postalCode: '',
      },
    },
  });
  
  const [errors, setErrors] = useState({});

  const roles = [{ value: "CEO", label: "CEO" }, { value: "CTO", label: "CTO" }, { value: "CFO", label: "CFO" }, { value: "Manager", label: "Manager" }, { value: "Employee", label: "Employee" }, { value: "Intern", label: "Intern" }];

  const handleInputChange = (prefix, index, field, value) => {
    const newState = { ...formState };
    if (prefix === 'contactInfo') {
      if (field.includes('.')) {
        const [fieldKey, subFieldKey] = field.split('.');
        newState[prefix][fieldKey][subFieldKey] = value;
      } else {
        newState[prefix][field] = value;
      }
    } else {
      newState[prefix][index][field] = value;
      if (field === 'ownershipPercentage') {
        value = Math.max(0, Math.min(value, 100 - calculateTotalOwnership(index)));
        newState[prefix][index][field] = value;
      }
    }
    setFormState(newState);
    setErrors({ ...errors, [`${prefix}.${index}.${field}`]: '' });
  };

  const calculateTotalOwnership = (excludeIndex) => {
    return formState.members.reduce((sum, member, index) => {
      return sum + (index !== excludeIndex ? Number(member.ownershipPercentage) : 0);
    }, 0);
  };

  const handleAddMember = () => {
    setFormState(prevState => ({
      ...prevState,
      members: [...prevState.members, { firstName: '', lastName: '', email: '', role: '', ownershipPercentage: '', birthdate: '' }]
    }));
  };

  const handleRemoveMember = (index) => {
    setFormState(prevState => ({
      ...prevState,
      members: prevState.members.filter((_, i) => i !== index)
    }));
  };

  const handleFillContactInfo = () => {
    if (formState.members.length === 1) {
      const director = formState.members[0];
      setFormState(prevState => ({
        ...prevState,
        contactInfo: {
          ...prevState.contactInfo,
          name: director.firstName,
          lastName: director.lastName,
          email: director.email,
        }
      }));
    } else if (formState.members.length > 1) {
      const selectedIndex = prompt(`Ingrese el número del director del cual desea copiar la información (1-${formState.members.length}):`);
      const index = parseInt(selectedIndex, 10) - 1;
      if (index >= 0 && index < formState.members.length) {
        const director = formState.members[index];
        setFormState(prevState => ({
          ...prevState,
          contactInfo: {
            ...prevState.contactInfo,
            name: director.firstName,
            lastName: director.lastName,
            email: director.email,
          }
        }));
      } else {
        alert('Número de director no válido.');
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        const company_info = {
          'company_name': localStorage.getItem('CompanyName'),  // Replace with your actual company_id
          'member': formState.members,
          'contact': formState.contactInfo
        };
        console.log(company_info)
        const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.rely.business' : 'http://127.0.0.1:5000';
        const response = await fetch(`${baseUrl}/api/company-info`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(company_info)
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        setSaved(true);
        localStorage.removeItem('CompanyName');
        localStorage.removeItem('currentStep');
        
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    formState.members.forEach((member, index) => {
      if (!member.firstName) {
        isValid = false;
        newErrors[`members.${index}.firstName`] = "Nombre es requerido.";
      }
      if (!member.lastName) {
        isValid = false;
        newErrors[`members.${index}.lastName`] = "Apellido es requerido.";
      }
      if (!member.email) {
        isValid = false;
        newErrors[`members.${index}.email`] = "Email es requerido.";
      }
      if (!member.role) {
        isValid = false;
        newErrors[`members.${index}.role`] = "Rol es requerido.";
      }
      if (!member.ownershipPercentage || member.ownershipPercentage > 100) {
        isValid = false;
        newErrors[`members.${index}.ownershipPercentage`] = "Porcentaje de propiedad es requerido y no puede exceder 100%.";
      }
      if (!member.birthdate) {
        isValid = false;
        newErrors[`members.${index}.birthdate`] = "Fecha de Nacimiento es requerida.";
      }
    });

    if (!formState.contactInfo.name) {
      isValid = false;
      newErrors['contactInfo.name'] = "Nombre de contacto es requerido.";
    }
    if (!formState.contactInfo.lastName) {
      isValid = false;
      newErrors['contactInfo.lastName'] = "Apellido de contacto es requerido.";
    }
    if (!formState.contactInfo.email) {
      isValid = false;
      newErrors['contactInfo.email'] = "Correo electrónico de contacto es requerido.";
    }

    const { country, state, city, address, postalCode } = formState.contactInfo.direction;
    if (!country) {
      isValid = false;
      newErrors['contactInfo.direction.country'] = "País es requerido.";
    }
    if (!state) {
      isValid = false;
      newErrors['contactInfo.direction.state'] = "Provincia / Estado es requerido.";
    }
    if (!city) {
      isValid = false;
      newErrors['contactInfo.direction.city'] = "Ciudad es requerida.";
    }
    if (!address) {
      isValid = false;
      newErrors['contactInfo.direction.address'] = "Dirección es requerida.";
    }
    if (!postalCode) {
      isValid = false;
      newErrors['contactInfo.direction.postalCode'] = "Código Postal es requerido.";
    }

    const totalOwnership = calculateTotalOwnership(-1);
    if (totalOwnership > 100) {
      isValid = false;
      newErrors['totalOwnership'] = "La suma total de porcentajes debe ser 100% o menos.";
    }

    setErrors(newErrors);
    return isValid;
  };

  if (!saved) {
    return (
      <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
        <h4 className="mb-[15px] pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
          Miembros de {localStorage.getItem('CompanyName') || 'LLC'}
        </h4>
        <form className="space-y-4" onSubmit={handleSubmit}>
          {formState.members.map((member, index) => (
            <div key={index} className="space-y-4">
              <div className="flex justify-between items-center w-full">
                <h5 className="text-lg font-semibold">Miembro #{index + 1}</h5>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <InputField
                  label="Nombre"
                  id={`members.${index}.firstName`}
                  value={member.firstName}
                  onChange={(e) => handleInputChange('members', index, 'firstName', e.target.value)}
                  error={errors[`members.${index}.firstName`]}
                />
                <InputField
                  label="Apellido"
                  id={`members.${index}.lastName`}
                  value={member.lastName}
                  onChange={(e) => handleInputChange('members', index, 'lastName', e.target.value)}
                  error={errors[`members.${index}.lastName`]}
                />
                <InputField
                  label="Email"
                  id={`members.${index}.email`}
                  type="email"
                  value={member.email}
                  onChange={(e) => handleInputChange('members', index, 'email', e.target.value)}
                  error={errors[`members.${index}.email`]}
                />
                <InputField
                  label="Fecha de Nacimiento"
                  id={`members.${index}.birthdate`}
                  type="date"
                  value={member.birthdate}
                  onChange={(e) => handleInputChange('members', index, 'birthdate', e.target.value)}
                  error={errors[`members.${index}.birthdate`]}
                />
                <SelectionField
                  label="Rol"
                  id={`members.${index}.role`}
                  options={roles}
                  value={member.role}
                  onChange={(e) => handleInputChange('members', index, 'role', e.target.value)}
                  error={errors[`members.${index}.role`]}
                />
                <InputField
                  label="P. de propiedad"
                  id={`members.${index}.ownershipPercentage`}
                  type="number"
                  value={member.ownershipPercentage}
                  onChange={(e) => handleInputChange('members', index, 'ownershipPercentage', e.target.value)}
                  error={errors[`members.${index}.ownershipPercentage`]}
                  className="md:w-1/3"
                />
              </div>
              {formState.members.length > 1 && (
                <div className="flex justify-center mt-2">
                  <button type="button" className="bg-red-500 text-white rounded px-4 py-2" onClick={() => handleRemoveMember(index)}>
                    Eliminar Miembro
                  </button>
                </div>
              )}
            </div>
          ))}

          <div className="flex justify-center my-4">
            <button type="button" className="bg-green-700 text-white rounded px-4 py-2" onClick={handleAddMember}>
              Agregar Miembro
            </button>
          </div>

          <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
            Información de Contacto
          </h4>
          <div className="flex justify-center my-4">
            <button type="button" className="bg-green-700 text-white rounded px-4 py-2" onClick={handleFillContactInfo}>
              Rellenar con información del Director
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <InputField
              label="Nombre"
              id="contactInfo.name"
              value={formState.contactInfo.name}
              onChange={(e) => handleInputChange('contactInfo', 0, 'name', e.target.value)}
              error={errors['contactInfo.name']}
            />
            <InputField
              label="Apellido"
              id="contactInfo.lastName"
              value={formState.contactInfo.lastName}
              onChange={(e) => handleInputChange('contactInfo', 0, 'lastName', e.target.value)}
              error={errors['contactInfo.lastName']}
            />
            <InputField
              label="Correo Electrónico"
              id="contactInfo.email"
              type="email"
              value={formState.contactInfo.email}
              onChange={(e) => handleInputChange('contactInfo', 0, 'email', e.target.value)}
              error={errors['contactInfo.email']}
            />
          </div>

          <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
            Dirección de Contacto
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <SelectionField
              label="País"
              id="contactInfo.direction.country"
              options={countries}
              value={formState.contactInfo.direction.country}
              onChange={(e) => handleInputChange('contactInfo', 0, 'direction.country', e.target.value)}
              error={errors['contactInfo.direction.country']}
            />
            <InputField
              label="Provincia / Estado"
              id="contactInfo.direction.state"
              value={formState.contactInfo.direction.state}
              onChange={(e) => handleInputChange('contactInfo', 0, 'direction.state', e.target.value)}
              error={errors['contactInfo.direction.state']}
            />
            <InputField
              label="Ciudad"
              id="contactInfo.direction.city"
              value={formState.contactInfo.direction.city}
              onChange={(e) => handleInputChange('contactInfo', 0, 'direction.city', e.target.value)}
              error={errors['contactInfo.direction.city']}
            />
            <InputField
              label="Dirección"
              id="contactInfo.direction.address"
              value={formState.contactInfo.direction.address}
              onChange={(e) => handleInputChange('contactInfo', 0, 'direction.address', e.target.value)}
              error={errors['contactInfo.direction.address']}
            />
            <InputField
              label="Código Postal"
              id="contactInfo.direction.postalCode"
              value={formState.contactInfo.direction.postalCode}
              onChange={(e) => handleInputChange('contactInfo', 0, 'direction.postalCode', e.target.value)}
              error={errors['contactInfo.direction.postalCode']}
            />
          </div>

          {errors['totalOwnership'] && (
            <p className="mt-2 text-sm text-red-600">{errors['totalOwnership']}</p>
          )}
          
          <div className="flex justify-center mt-6">
            <button type="submit" className="bg-green-500 text-white rounded px-4 py-2">
              Enviar
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return <SuccessCompanyInfo />;
  }
};

export default UserInfo;
