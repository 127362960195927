import React from 'react';
import axios from 'axios';
import Card from 'components/card';
import { useAuth0 } from '@auth0/auth0-react';

const Password = () => {
  const { user, getAccessTokenSilently } = useAuth0();

  const handlePasswordResetRequest = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://dev-41h-z2e6.us.auth0.com/api/v2/',
        scope: 'update:users'
      });

      const options = {
        method: 'POST',
        url: `https://dev-41h-z2e6.us.auth0.com/dbconnections/change_password`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          client_id: "QlCzo0wIsaVIXJbfl0Hdbr3aRlWp8WN8",
          email: user.email,  // Utiliza el email del usuario autenticado
          connection: 'Username-Password-Authentication'
        }
      };

      axios.request(options).then(function (response) {
        console.log(response.data);
        alert('Password reset email sent successfully.');
      }).catch(function (error) {
        console.error(error);
        alert('An error occurred while sending the password reset email.');
      });
    } catch (error) {
      console.error(error);
      alert('An error occurred while requesting the token.');
    }
  };

  return (
    <Card extra={'w-full p-4'}>
      <div className="w-full px-2">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Reiniciar Contraseña
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Enviaremos un mail a tu correo para que puedas reiniciar tu contraseña.
        </p>
      </div>
      <div className="mt-7 flex justify-end">
        <button
          className="rounded-xl bg-blue-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-blue-600 active:bg-blue-700 dark:bg-green-700 dark:text-white dark:hover:bg-green-800 dark:active:bg-green-700"
          onClick={handlePasswordResetRequest}
        >
          Enviar mail
        </button>
      </div>
    </Card>
  );
};

export default Password;
