import React, { useState, useEffect } from 'react';
import LogoImage from "assets/img/account/paupscale.png";
function TermsAndConditions() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Cambia a false después de 3 segundos

    return () => clearTimeout(timer); // Limpia el temporizador si el componente se desmonta
  }, []);

  const handleAccept = () => {
    const baseUrl = process.env.NODE_ENV === 'production' ? 'https://app.rely.business/' : 'http://localhost:3000';
    window.location.href = baseUrl;
  };

  return (
    <div className="flex items-center justify-center h-screen bg-green-700">
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="bouncing-ball"></div>
        </div>
      ) : (
        <div className="w-full max-w-4xl p-4 bg-green-800 rounded-lg shadow-lg overflow-y-auto" style={{ maxHeight: '90vh' }}>
                        <div className={`ml-[25px] mt-[20px] mr-[25px] flex items-center `}>
                <img src={LogoImage} alt="Logo" className="w-1/6 mx-auto my-auto" />
              </div>
          <h1 className="text-2xl font-bold text-white text-center">Términos y Condiciones</h1>
          <div className="text-sm mt-4 space-y-4 text-white">
            <p>Última actualización: 2 de abril de 2023</p>
            <p>Por favor lea estos términos y condiciones cuidadosamente antes de utilizar Nuestro Servicio.</p>
            <p>Estos términos y condiciones rigen la prestación de servicios por parte de Rely LLC a nuestros clientes en relación con la apertura de compañías de responsabilidad limitada (LLCs) en los Estados Unidos, apertura de cuentas bancarias y apertura de cuentas en intercambios de criptomonedas. Al utilizar nuestros servicios, usted acepta estar vinculado por estos términos y condiciones.</p>
            <div className="space-y-4">
              <h2 className="text-lg font-semibold">Servicios</h2>
              <ul className="list-disc list-inside pl-4">
                <li>Asistencia en la formación de compañías de responsabilidad limitada (LLCs) en los Estados Unidos.</li>
                <li>Asistencia en la apertura de cuentas bancarias en los Estados Unidos.</li>
                <li>Asistencia en la apertura de cuentas en intercambios de criptomonedas.</li>
                <li>Asistencia en el lanzamiento de una página de aterrizaje y paquetes de branding para su negocio.</li>
              </ul>
              <p>Tenga en cuenta que actuamos como intermediarios al proporcionar estos servicios. No garantizamos el éxito de la formación de las LLCs, la apertura de cuentas bancarias ni la apertura de cuentas en intercambios de criptomonedas. Utilizaremos esfuerzos razonables para asistirle, pero no podemos garantizar ningún resultado específico.</p>
            </div>
            <div className="space-y-4">
              <h2 className="text-lg font-semibold">Honorarios y Pago</h2>
              <p>Usted acepta pagarnos los honorarios especificados en nuestro sitio web o como se acuerde entre nosotros. Nuestros honorarios no incluyen impuestos ni otros cargos que puedan ser impuestos por la ley aplicable, y usted es responsable de pagar dichos impuestos o cargos.</p>
            </div>
            <div className="space-y-4">
              <h2 className="text-lg font-semibold">Información y Confidencialidad</h2>
              <p>Para poder proporcionar nuestros servicios, necesitaremos cierta información de usted, incluyendo información personal e información sobre su negocio. Usted declara y garantiza que toda la información que nos proporciona es precisa, completa y no engañosa. Mantendremos toda la información que nos proporcione confidencial, excepto según lo requiera la ley o según sea necesario para proporcionar nuestros servicios.</p>
            </div>
            <div className="space-y-4">
              <h2 className="text-lg font-semibold">Propiedad Intelectual</h2>
              <p>Todos los materiales, incluyendo, sin limitación, logotipos, marcas comerciales y diseños, proporcionados por nosotros a usted en conexión con nuestros servicios son nuestra propiedad exclusiva, y no puede utilizarlos sin nuestro consentimiento expreso por escrito.</p>
            </div>
            <div className="space-y-4">
              <h2 className="text-lg font-semibold">Limitación de Responsabilidad</h2>
              <p>En la máxima medida permitida por la ley, no seremos responsables por daños indirectos, especiales, incidentales, consecuentes o punitivos, incluyendo, sin limitación, pérdidas de beneficios o interrupción del negocio, que surjan de o en conexión con nuestros servicios, incluso si hemos sido advertidos de la posibilidad de tales daños.</p>
            </div>
            <div className="space-y-4">
              <h2 className="text-lg font-semibold">Enmiendas</h2>
              <p>Podemos modificar estos términos y condiciones en cualquier momento publicando los términos y condiciones modificados en nuestro sitio web. Su uso continuado de nuestros servicios después de cualquier modificación constituye su aceptación de los términos y condiciones modificados.</p>
            </div>
            <div className="space-y-4">
              <h2 className="text-lg font-semibold">Acuerdo Completo</h2>
              <p>Estos términos y condiciones constituyen el acuerdo completo entre nosotros y usted con respecto a nuestros servicios y reemplazan todas las comunicaciones y propuestas anteriores o contemporáneas, ya sean orales o escritas, entre nosotros y usted.</p>
            </div>
          </div>
          <button className="mt-4 w-full bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-50 text-white rounded px-6 py-2" onClick={handleAccept}>Aceptar y Continuar</button>
        </div>
      )}
    </div>
  );
}

export default TermsAndConditions;