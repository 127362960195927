const Footer = () => {
  return (
    <div className="flex w-full flex-col items-center justify-between px-1 pb-8 pt-3 lg:px-8 xl:flex-row">
      <p className="mb-4 text-center text-sm font-medium text-gray-600 sm:!mb-0 md:text-lg">
        ©{1900 + new Date().getYear()} Rely. All Rights Reserved.
      </p>
      <div>
        <ul className="flex flex-wrap items-center gap-3 sm:flex-nowrap md:gap-10">
          {/* <li>
            <a
              target="blank"
              href="mailto:support@rely.business"
              className="text-base font-medium text-gray-600 hover:text-gray-600"
            >
              Soporte
            </a>
          </li> */}
          <li>
            <a
              target="new_blank"
              href="https://app.rely.business/terminos"
              className="text-base font-small text-gray-600 hover:text-gray-600"
            >
              Términos y Condiciones
            </a>
          </li>
          <li>
            <a
              target="new_blank"
              href="https://app.rely.business/privacidad"
              className="text-base font-small text-gray-600 hover:text-gray-600"
            >
              Política de Privacidad
            </a>
          </li>
          <li>
            <a
              target="new_blank"
              href="https://medium.com/@relycompany"
              className="text-base font-medium text-gray-600 hover:text-gray-600"
            >
              Blog
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
